import { UserType } from '@botfabrik/engine-domain';
import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import useTranslation from '../../../../../locales/useTranslation';
import { ChatMessage } from '../../../../../types';
import styles from '../../../Message.module.scss';
import ProblemReport from './ProblemReport';

interface Props {
  message: ChatMessage;
  problemReportingEnabled: boolean;
  isAnimated: boolean;
  isFirstMessageBySender: boolean;
  isLastMessageBySender: boolean;
  children: ReactNode;
  onProblemReport?: (problem: string | null) => void;
}

const BotMessage = forwardRef<HTMLLIElement, Props>(
  (
    {
      message,
      problemReportingEnabled,
      isAnimated,
      isFirstMessageBySender,
      isLastMessageBySender,
      children,
      onProblemReport,
    },
    ref
  ) => {
    const translate = useTranslation();
    const isBot = message.sender.type === UserType.BOT;
    return (
      <li
        ref={ref}
        className={classNames(
          styles.message,
          styles.messageBot,
          isFirstMessageBySender && styles.messageBotFirst,
          isLastMessageBySender && styles.messageBotLast,
          'chat__bubbleContainer',
          isAnimated && styles.messageBotAnimated,
          isAnimated && 'chat__bubbleContainer--animated',
          isBot ? 'chat__botBubbleContainer' : 'chat__agentBubbleContainer',
          isFirstMessageBySender && 'chat__bubbleContainer--first',
          isLastMessageBySender && 'chat__bubbleContainer--last'
        )}
      >
        <img
          className={classNames(
            styles.avatar,
            isBot ? styles.botAvatar : styles.agentAvatar,
            'chat__avatar',
            isBot ? 'chat__botAvatar' : 'chat__agentAvatar'
          )}
          src={`${message.sender.avatar}`}
          alt={isBot ? 'Bot' : 'Agent'}
          referrerPolicy="no-referrer"
        />

        <div
          className={classNames(
            styles.bubble,
            styles.bubbleLeft,
            'chat__bubble',
            isBot ? 'chat__botBubble' : 'chat__agentBubble'
          )}
          tabIndex={0}
          aria-label={translate(
            isBot ? 'bot-bubble.label' : 'agent-bubble.label'
          )}
        >
          <div className={classNames(styles.leftText)}>{children}</div>
        </div>

        {problemReportingEnabled && onProblemReport && (
          <ProblemReport
            reported={!!message.problemReport?.length}
            onReport={onProblemReport}
          />
        )}
      </li>
    );
  }
);

export default BotMessage;
