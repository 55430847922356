import classNames from 'classnames';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { requestNLUEvent, sendMessageFromUser } from '../actions';
import { connect, restartChat } from '../actions/connectionActions';
import { useAppDispatch, useAppSelector } from '../hooks';
import Chat from './chat';
import styles from './RootScene.module.scss';
import StartScreen from './start';
import useSetDocumentLanguageEffect from './useSetDocumentLanguageEffect';

/**
 * Events von der Webseite, die den Webclient einbettet.
 */
const INCOMMING_EVENTS = {
  TRIGGER_ACTION: 'TRIGGER_ACTION',
  TRIGGER_STORY: 'TRIGGER_STORY',
  CHAT_MESSAGE_FROM_GUEST: 'CHAT_MESSAGE_FROM_GUEST',
  ON_CHAT_WINDOW_OPEN: 'ON_CHAT_WINDOW_OPEN',
  ON_CHAT_WINDOW_CLOSE: 'ON_CHAT_WINDOW_CLOSE',
  RESTART_CHAT: 'RESTART_CHAT', // Wird von Bubble CMS aufgerufen, wenn der Chat neu gestartet werden soll
};

type Props = {
  clientName: string;
};

const RootScene = ({ clientName }: Props) => {
  useSetDocumentLanguageEffect();

  const dispatch = useAppDispatch();

  const useStartScreen = useAppSelector(
    (state) => state.startScreen.useStartScreen
  );
  const startScreenStatus = useAppSelector(
    (state) => state.startScreen.startScreenStatus
  );

  const [cssClassName, setCssClassName] = useState<
    string | string[] | undefined
  >(undefined);

  useEffect(() => {
    const parsedQuery = queryString.parse(window.location.search);

    if (parsedQuery.cssClassName) {
      setCssClassName(parsedQuery.cssClassName as string);
    }

    /**
     * Meldung von ausserhalb (z.B. vom Wordpress Content-Bereich)
     */
    const handleOnMessageFromOutside = (event: any) => {
      switch (event.data.type) {
        case INCOMMING_EVENTS.TRIGGER_ACTION:
          dispatch(event.data.action);
          break;
        case INCOMMING_EVENTS.TRIGGER_STORY:
          dispatch(requestNLUEvent(event.data.story));
          break;
        case INCOMMING_EVENTS.ON_CHAT_WINDOW_OPEN:
          dispatch({ type: 'webclient.chatwindow.opened' });
          break;
        case INCOMMING_EVENTS.ON_CHAT_WINDOW_CLOSE:
          dispatch({ type: 'webclient.chatwindow.closed' });
          break;
        case INCOMMING_EVENTS.CHAT_MESSAGE_FROM_GUEST:
          dispatch(sendMessageFromUser(event.data.text));
          break;
        case INCOMMING_EVENTS.RESTART_CHAT:
          dispatch(restartChat(clientName, parsedQuery));
          break;
      }
    };

    dispatch(connect(clientName, parsedQuery));

    window.addEventListener(
      'message',
      (event) => handleOnMessageFromOutside(event),
      false
    );
    return () =>
      window.removeEventListener('message', handleOnMessageFromOutside);
  }, [clientName, dispatch]);

  const loading = useStartScreen === undefined;
  const showChat = useStartScreen === false || startScreenStatus === 'HIDDEN';

  return (
    <div
      className={classNames(
        styles.chatwindow,
        'chat__chatwindow',
        cssClassName
      )}
    >
      {loading ? null : showChat ? (
        <Chat clientName={clientName} />
      ) : (
        <StartScreen />
      )}
    </div>
  );
};

export default RootScene;
