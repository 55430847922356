import {
  BotUser,
  Button,
  TextMessage,
  UserType,
} from '@botfabrik/engine-domain';
import classNames from 'classnames';
import { useLayoutEffect, useRef } from 'react';
import { ChatMessage } from '../../../types';
import styles from './MessageContainer.module.scss';
import GeneralConditions from './general-conditions';
import Message from './message';
import BotMessage from './message/bot-message';
import TypingIndicator from './typing-indicator';

const BotTypingMessage = Object.assign(
  { _id: 'typing-indicator' },
  new TextMessage('', new BotUser())
);

type Props = {
  messages: ChatMessage[];
  isBotTyping: boolean;
  showGeneralConditions: boolean;
  problemReportingEnabled: boolean;
  onProblemReport: (actionId: string, problem: string | null) => void;
  onButtonClick: (button: Button) => void;
  onLinkClick: (href: string) => void;
};

const Conversation = ({
  messages,
  isBotTyping,
  showGeneralConditions,
  problemReportingEnabled,
  onProblemReport,
  onButtonClick,
  onLinkClick,
}: Props) => {
  const isFirstRender = useRef(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const typingMessageRef = useRef<HTMLLIElement | null>(null);
  const lastMessageRef = useRef<HTMLLIElement | null>(null);

  useLayoutEffect(() => {
    if (messages.length && containerRef.current) {
      if (typingMessageRef.current) {
        const scroll = typingMessageRef.current.offsetTop;
        containerRef.current.scrollTop = scroll;
      } else if (lastMessageRef.current) {
        const scroll = lastMessageRef.current.offsetTop;
        containerRef.current.scrollTop = scroll;
      }

      if (isFirstRender.current) {
        isFirstRender.current = false;
      }
    }
  }, [
    messages,
    typingMessageRef.current,
    lastMessageRef.current,
    isFirstRender,
  ]);

  let senderOfPreviousMessage: UserType | undefined = undefined;
  const nbrOfMessages = messages.length;

  return (
    <div
      ref={containerRef}
      className={classNames(styles.messageContainer, 'chat__messageContainer')}
      style={{
        scrollBehavior:
          messages.length && isFirstRender.current ? 'auto' : 'smooth',
      }}
      id="MessageContainer"
      role="log"
      aria-live="polite"
    >
      {showGeneralConditions && <GeneralConditions />}
      <ul className={styles.messages}>
        {messages.map((msg, index) => {
          const isFirstMessageBySender = getIsFirstMessageBySender(
            msg,
            senderOfPreviousMessage
          );
          const isLastMessageBySender = getIsLastMessageBySender(
            msg,
            index,
            messages,
            isBotTyping
          );
          senderOfPreviousMessage = msg.sender.type;

          const isLast = index + 1 === nbrOfMessages;
          return (
            <Message
              ref={isLast ? lastMessageRef : null}
              key={isLast && !isBotTyping ? 'last' : msg._id}
              message={msg}
              problemReportingEnabled={problemReportingEnabled}
              isFirstMessageBySender={isFirstMessageBySender}
              isLastMessageBySender={isLastMessageBySender}
              onButtonClick={onButtonClick}
              onLinkClick={onLinkClick}
              onProblemReport={onProblemReport}
            />
          );
        })}
        {isBotTyping && (
          <BotMessage
            ref={typingMessageRef}
            key="last"
            message={BotTypingMessage}
            problemReportingEnabled={false}
            isAnimated
            isFirstMessageBySender={senderOfPreviousMessage !== UserType.BOT}
            isLastMessageBySender={true}
          >
            <TypingIndicator isTyping />
          </BotMessage>
        )}
      </ul>
    </div>
  );
};

const getIsFirstMessageBySender = (
  msg: ChatMessage,
  senderOfPreviousMessage: UserType | undefined
): boolean => {
  return (
    !senderOfPreviousMessage || senderOfPreviousMessage !== msg.sender.type
  );
};

const getIsLastMessageBySender = (
  msg: ChatMessage,
  index: number,
  msgs: ChatMessage[],
  isBotTyping: boolean
) => {
  const sender = msg.sender.type;
  const nbrOfMessages = msgs.length;
  const isLast = index + 1 === nbrOfMessages;
  if (isLast) {
    const isMessageFromBot = sender === UserType.BOT;
    return !(isMessageFromBot && isBotTyping);
  } else {
    return sender !== msgs.at(index + 1)?.sender.type;
  }
};

export default Conversation;
